<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 513 622"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_di_696_2147)">
      <path
        d="M499.03 567.22V368.982C499.03 351.575 485.16 337.495 468.148 337.495H366.556V426.08C366.556 461.448 338.184 490.275 303.374 490.275H242.156V567.22C242.156 584.516 256.015 598.597 273.038 598.597H468.148C485.171 598.597 499.03 584.516 499.03 567.22Z"
        fill="#5F6164"
      />
    </g>
    <path
      d="M344.715 426.08V220.968H270.403C245.414 220.968 225.117 200.235 225.117 174.845V118.522H42.0112C19.0956 118.522 0.654053 137.26 0.654053 160.543V426.08C0.654053 449.253 19.0956 468.1 42.0112 468.1H303.358C326.164 468.1 344.715 449.253 344.715 426.08Z"
      fill="url(#paint0_linear_696_2147)"
    />
    <path
      d="M442.385 174.844V23.9483C442.385 10.7546 431.91 0 418.925 0H270.41C257.424 0 246.948 10.7546 246.948 23.9483V174.844C246.948 188.038 257.424 198.793 270.41 198.793H418.925C431.91 198.793 442.385 188.038 442.385 174.844Z"
      fill="url(#paint1_linear_696_2147)"
    />
    <defs>
      <filter
        id="filter0_di_696_2147"
        x="229.156"
        y="327.495"
        width="282.873"
        height="294.103"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="6.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_696_2147"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_696_2147"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-10" />
        <feGaussianBlur stdDeviation="11" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0166667 0 0 0 0 0.0166667 0 0 0 0 0.0166667 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_696_2147"
        />
      </filter>
      <linearGradient
        id="paint0_linear_696_2147"
        x1="0.654053"
        y1="118.522"
        x2="101.798"
        y2="528.09"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#373737" />
        <stop offset="0.229049" stop-color="#252628" />
        <stop offset="1" stop-color="#000309" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_696_2147"
        x1="344.667"
        y1="0"
        x2="344.667"
        y2="193.343"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#57F372" />
        <stop offset="0.885788" stop-color="#26CE43" />
        <stop offset="1" stop-color="#1EC83B" />
      </linearGradient>
    </defs>
  </svg>
</template>
