import axios from "axios";
import { url_event_hook } from "@/constants/constant.js";

function postRequest(url, data, headers) {
  axios.post(url, data, headers);
  // .then(function (response) {
  //   console.log(response);
  // })
  // .catch(function (error) {
  //   console.log(error);
  // });
}

export let doPost = async (type, success, form) => {
  let url = url_event_hook;
  // try {
  await postRequest(
    url,
    { type, success, data: form },
    {
      "X-VEZHAM_AUTH":
        "eyJpdiI6IlBRaUlWUm5RXC9oQm9RNUQzMkFldG9SzRLaWowQ3E0RGFcLzZkR2JKNHFNQ3l4TlJTOGxFjIjoiODgzMjk4NDBDFmZjY0OTQ2YTM0ZTM0OWU1MTJmODFlMWI1OGEwOTZmNDg0ZTU0OCJ9",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  );
  // console.log("API | SUCCESS | url: " + url + " | res: " + res);
  // } catch (err) {
  // console.error("API | ERROR | url: " + url);
  // }
};
