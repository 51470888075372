<template>
  <section id="contact" class="contact">
    <div class="container">
      <div class="row mt-1 p-4">
        <div class="col-lg-4 colleft">
          <div class="info">
            <div class="email">
              <div class="row">
                <div class="col-md-3">
                  <ICON_Mail :color="icon_color" :size="icon_size" />
                </div>
                <div class="col-md-9">
                  <h4>Email:</h4>
                  <p><a href="mailto:desk@vezham.com"> desk@vezham.com</a></p>
                </div>
              </div>
            </div>
            <div class="phone">
              <div class="row">
                <div class="col-md-3">
                  <ICON_Phone :color="icon_color" :size="icon_size" />
                </div>
                <div class="col-md-9">
                  <h4>Call:</h4>
                  <p><a href="tel:+919080664904"> +91 90806 64904</a></p>
                </div>
              </div>
            </div>
            <div class="address">
              <div class="row">
                <div class="col-md-3">
                  <ICON_location :color="icon_color" :size="icon_size" />
                </div>
                <div class="col-md-9">
                  <h4>Location:</h4>
                  <P
                    >Vezham Technologies Private Limited, 134/A W3 Chettiyar
                    Street, Anaimalaiyanpatty, Theni (DT) - 625 526, Tamil Nadu,
                    India</P
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-8 mt-5 mt-lg-0 contactform" id="exceljutacontact">
          <!--Section: Contact v.2-->
          <section class="mb-4">
            <!--Section heading-->
            <h2 class="h1-responsive font-weight-bold text-center my-4">
              Contact Us
            </h2>
            <!--Section description-->
            <p class="text-center w-responsive mx-auto mb-5">
              Do you have any questions? Please do not hesitate to contact us
              directly. Our team will come back to you swiftly.
            </p>

            <div class="row">
              <form role="form" class="php-email-form">
                <div class="row">
                  <div class="col-md-6 form-group mt-3">
                    <label>First Name</label>
                    <input
                      type="text"
                      name="firstname"
                      class="form-control"
                      id="name"
                      placeholder="Your First Name"
                      required=""
                      v-model="first_name"
                    />
                  </div>
                  <div class="col-md-6 form-group mt-3">
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="lastname"
                      class="form-control"
                      id="name"
                      placeholder="Your Last Name"
                      required=""
                      v-model="last_name"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 form-group mt-3">
                    <label>Email</label>
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      required=""
                      v-model="email"
                    />
                  </div>
                  <div class="col-md-6 form-group mt-3">
                    <label>Phone Number</label>
                    <input
                      type="tel"
                      name="phone"
                      class="form-control"
                      id="name"
                      placeholder="Your Phone Number"
                      required=""
                      v-model="mobile"
                    />
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label>Message</label>
                  <textarea
                    class="form-control"
                    name="message"
                    rows="3"
                    placeholder="Write Your Message"
                    required=""
                    v-model="query"
                  ></textarea>
                </div>
                <div class="text-center mt-3">
                  <button type="button" value="Send" @click="doSubmit()">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ICON_Mail from "@/assets/icons/Mail.vue";
import ICON_Phone from "@/assets/icons/Phone.vue";
import ICON_location from "@/assets/icons/Location.vue";

import { doPost } from "@/utility/action.js";

export default {
  components: {
    ICON_Mail,
    ICON_Phone,
    ICON_location,
  },
  data() {
    return {
      icon_color: "black",
      icon_size: 64,
      first_name: null,
      last_name: null,
      email: null,
      mobile: null,
      query: null,
    };
  },
  methods: {
    doSubmit() {
      if (
        this.first_name &&
        this.last_name &&
        this.email &&
        this.mobile &&
        this.query
      ) {
        doPost("contact_us", {
          type: window.location.href,
          success: this.$toast.success(`Submited Successfully`, {
            position: "top",
            duration: 4000,
            max: 1,
          }),
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          mobile: this.mobile,
          query: this.query,
        });
      }
    },
  },
};
</script>
<style scoped>
.contactform {
  padding: 0px 30px;
}
.address,
.email,
.phone {
  padding-bottom: 30px;
}
.contact .php-email-form {
  width: 100%;
  background: #fff;
}
.contact .php-email-form .form-group {
  padding-bottom: 8px;
}
.contact .php-email-form input {
  height: 44px;
}
.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
  border: none;
  padding: 0px;
  border-bottom: 2px solid;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.colleft {
  background-image: url("../../assets/images/ContactBG.png");
  padding: 30px;
  border-radius: 15px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (max-width: 991px) {
  .contact {
    padding: 40px 30px !important ;
  }
  .contactform {
    padding: 0px !important;
  }
  .contact .info h4 {
    padding: 20px 0 0 20px !important;
  }

  .contact .info p {
    padding: 10px 0 0 20px !important;
  }
}

#exceljutacontact {
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  transition: all 0.2s;
}

.contact {
  padding: 90px 0px;
  background-color: #fff;
  position: relative;
}

.contact:before {
  content: "";
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.contact .container {
  position: relative;
}

.contact .info {
  width: 100%;
}

.contact .info h4 {
  padding: 20px 0 0 0;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #000;
}

.contact .info p {
  padding: 10px 0 0 0;
  margin-bottom: 0;
  font-size: 14px;
  color: #000;
}
button {
  padding: 10px 32px;
  color: #0880e8;
  transition: 0.4s;
  border-radius: 50px;
  border: 2px solid #0880e8;
  background: #fff;
}
button:hover {
  background: #0880e8;
  color: #fff;
}
</style>
