<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 387 467"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_di_439_425)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M192.431 4.04395L13 46.6229V159.144C13 286.965 78.6212 392.428 188.53 441.25L192.475 443.002L196.419 441.25C307.347 391.973 373.57 286.517 373.57 158.99L371.783 46.5947L192.431 4.04395Z"
        fill="url(#paint0_linear_439_425)"
      />
    </g>
    <g filter="url(#filter1_dddiiii_439_425)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M192.242 29L33 66.7881V166.648C33 280.088 91.2377 373.684 188.78 417.012L192.281 418.568L195.781 417.012C294.228 373.281 353 279.69 353 166.512L351.414 66.763L192.242 29Z"
        fill="url(#paint1_linear_439_425)"
      />
    </g>
    <g filter="url(#filter2_di_439_425)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M169.38 128.125C164.742 133.827 162.71 141.417 162.71 147.588V238.947H129.552V147.588C129.552 135.306 133.336 119.891 143.657 107.203C154.471 93.9069 171.362 85 194.33 85C216.612 85 232.839 95.2744 243.082 108.262C252.85 120.648 257.447 135.76 257.447 147.588V193.474C257.447 202.63 250.024 210.053 240.868 210.053C231.711 210.053 224.289 202.63 224.289 193.474V147.588C224.289 143.27 222.237 135.378 217.046 128.795C212.33 122.815 205.29 118.158 194.33 118.158C180.734 118.158 173.525 123.029 169.38 128.125Z"
        fill="url(#paint2_linear_439_425)"
      />
      <path
        d="M239.981 193.483C239.981 193.977 240.382 194.378 240.876 194.378C241.37 194.378 241.772 193.977 241.772 193.483H239.981ZM147.035 238.956V147.597H145.244V238.956H147.035ZM147.035 147.597C147.035 138.535 149.896 127.244 157.222 118.237C164.521 109.263 176.31 102.483 194.338 102.483V100.692C175.803 100.692 163.492 107.69 155.833 117.107C148.199 126.491 145.244 138.206 145.244 147.597H147.035ZM194.338 102.483C210.653 102.483 222.04 109.797 229.369 119.092C236.725 128.419 239.981 139.726 239.981 147.597H241.772C241.772 139.321 238.378 127.623 230.776 117.983C223.147 108.31 211.265 100.692 194.338 100.692V102.483ZM239.981 147.597V193.483H241.772V147.597H239.981Z"
        fill="#7B8698"
      />
    </g>
    <g filter="url(#filter3_di_439_425)">
      <path
        d="M271.986 175.778H116.014C109.379 175.778 104 181.157 104 187.792V306.999C104 313.634 109.379 319.013 116.014 319.013H271.986C278.621 319.013 284 313.634 284 306.999V187.792C284 181.157 278.621 175.778 271.986 175.778Z"
        fill="url(#paint3_linear_439_425)"
      />
      <path
        d="M193.817 267.941C208.729 267.941 220.817 255.852 220.817 240.941C220.817 226.029 208.729 213.941 193.817 213.941C178.906 213.941 166.817 226.029 166.817 240.941C166.817 255.852 178.906 267.941 193.817 267.941Z"
        fill="url(#paint4_linear_439_425)"
      />
    </g>
    <defs>
      <filter
        id="filter0_di_439_425"
        x="0"
        y="-5.95605"
        width="386.57"
        height="471.958"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="6.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_439_425"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_439_425"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-10" />
        <feGaussianBlur stdDeviation="11" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0166667 0 0 0 0 0.0166667 0 0 0 0 0.0166667 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_439_425"
        />
      </filter>
      <filter
        id="filter1_dddiiii_439_425"
        x="4.39"
        y="0.389999"
        width="377.22"
        height="446.788"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.38" />
        <feGaussianBlur stdDeviation="0.14" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.55 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_439_425"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-1.1" />
        <feGaussianBlur stdDeviation="0.415" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0980392 0 0 0 0 0.870588 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_439_425"
          result="effect2_dropShadow_439_425"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="14.305" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0967593 0 0 0 0 0.870833 0 0 0 0 0 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_439_425"
          result="effect3_dropShadow_439_425"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect3_dropShadow_439_425"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.0588235 0 0 0 0 0.172549 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect4_innerShadow_439_425"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="9.35" />
        <feGaussianBlur stdDeviation="5.09" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0313726 0 0 0 0 0.282353 0 0 0 0 0 0 0 0 0.45 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_innerShadow_439_425"
          result="effect5_innerShadow_439_425"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="17.61" />
        <feGaussianBlur stdDeviation="14.72" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0314815 0 0 0 0 0.283333 0 0 0 0 0 0 0 0 0.35 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_innerShadow_439_425"
          result="effect6_innerShadow_439_425"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.1" />
        <feGaussianBlur stdDeviation="0.55" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.34902 0 0 0 0 0.756863 0 0 0 0 0.298039 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect6_innerShadow_439_425"
          result="effect7_innerShadow_439_425"
        />
      </filter>
      <filter
        id="filter2_di_439_425"
        x="116.552"
        y="75"
        width="153.895"
        height="186.956"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="6.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_439_425"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_439_425"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-10" />
        <feGaussianBlur stdDeviation="11" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0166667 0 0 0 0 0.0166667 0 0 0 0 0.0166667 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_439_425"
        />
      </filter>
      <filter
        id="filter3_di_439_425"
        x="91"
        y="165.778"
        width="206"
        height="176.234"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="6.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_439_425"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_439_425"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-10" />
        <feGaussianBlur stdDeviation="11" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0166667 0 0 0 0 0.0166667 0 0 0 0 0.0166667 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_439_425"
        />
      </filter>
      <linearGradient
        id="paint0_linear_439_425"
        x1="193.285"
        y1="4.04395"
        x2="193.285"
        y2="443.002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#83F281" />
        <stop offset="1" stop-color="#59C14C" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_439_425"
        x1="193"
        y1="29"
        x2="193"
        y2="418.568"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#55C4FA" />
        <stop offset="1" stop-color="#0A80F8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_439_425"
        x1="129.552"
        y1="85"
        x2="181.125"
        y2="261.276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#373737" />
        <stop offset="0.229049" stop-color="#252628" />
        <stop offset="1" stop-color="#000309" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_439_425"
        x1="194"
        y1="175.778"
        x2="194"
        y2="319.013"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F4F7FC" />
        <stop offset="1" stop-color="#E3E6EA" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_439_425"
        x1="193.817"
        y1="213.941"
        x2="193.828"
        y2="267.941"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#565A5D" />
        <stop offset="1" stop-color="#2B2B2B" />
      </linearGradient>
    </defs>
  </svg>
</template>
