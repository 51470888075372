<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_b_28_262)">
      <path
        :fill="color"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.7527 41C23.7527 41 36.5053 28.2727 36.5053 18.7273C36.5053 11.6982 30.7958 6 23.7527 6C16.7095 6 11 11.6982 11 18.7273C11 28.2727 23.7527 41 23.7527 41ZM28.5349 18.7273C28.5349 21.3632 26.3938 23.5 23.7527 23.5C21.1115 23.5 18.9704 21.3632 18.9704 18.7273C18.9704 16.0914 21.1115 13.9545 23.7527 13.9545C26.3938 13.9545 28.5349 16.0914 28.5349 18.7273Z"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_28_262"
        x="6.00452"
        y="5.59952"
        width="35.8553"
        height="37.0103"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="2" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_28_262"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_28_262"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
    size: {
      type: Number,
      default: 48,
    },
  },
};
</script>
